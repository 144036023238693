port module Backoffice exposing
    ( Flags
    , Model
    , ModelData
    , Msg
    , SubModels
    , main
    )

import Backoffice.AccountSettings.AccountSettings as AccountSettings
import Backoffice.Accounting as Accounting
import Backoffice.Api.Config as ApiConfig
import Backoffice.Api.SeatsIO.Query exposing (Chart)
import Backoffice.Api.Tenant.Query as Tenant exposing (ExtendedOpeningHoursSettings, Tenant, TenantId)
import Backoffice.Api.Tenant.QueryByUser as TenantsByUser
import Backoffice.Api.Tenant.QuerySlim as TenantSlim
import Backoffice.Api.Types exposing (Location)
import Backoffice.Commons as Commons
import Backoffice.Concession.Collections.List as Collections
import Backoffice.Concession.Combos.List as Combos
import Backoffice.Concession.Concessions as Concessions
import Backoffice.CreditSales.CreditSales as CreditSales
import Backoffice.Library.Library as Library
import Backoffice.Loyalty.Loyalty as Loyalty
import Backoffice.MaintenanceMode as MaintenanceMode
import Backoffice.Menu as Menu
import Backoffice.MetabaseReport
import Backoffice.MovieDatabase.FTMDB as FTMDB
import Backoffice.Planning.Planning as Planning
import Backoffice.Poc.ActorDetail as ActorDetail
import Backoffice.Poc.DirectorDetail as DirectorDetail
import Backoffice.Poc.Graph as Graph
import Backoffice.Poc.SeatsIO as SeatsIO
import Backoffice.Pricing as Pricing
import Backoffice.Pricing_v2.Pricing as Pricing_v2
import Backoffice.Routes as Routes exposing (Page(..))
import Backoffice.Scheduler_v2 as Scheduler_v2
import Backoffice.Toggles as Toggles
import Backoffice.User as UserPage
import Backoffice.UserManagement as UserManagement
import Backoffice.Utils.ErrorReporting as ErrorReporting
import Backoffice.Utils.GraphQL
import Browser exposing (Document)
import Browser.Navigation as Nav
import Cmd.Extra as Cmd
import CookieData as Cd
import CookieFunctions as Cf
import Css
import Css.Global
import DX.Theme as Theme
import DX.Utilities as Tw
import Glue
import Glue.Lazy
import Graphql.Http as GH
import Html.Styled exposing (Html, div)
import Http
import List.Extra as List
import Maybe.Extra as Maybe
import Process
import RemoteData as RD
import Store exposing (Store)
import Svg.Styled.Attributes as Attr
import Task
import Time
import Ui.Spinner exposing (viewSpinnerWithText)
import Ui.Toast as Toast
import Url
import Utils.Http
import Utils.Id as Id
import Utils.List as List


maxShownToasts : Int
maxShownToasts =
    4



-- MODEL


type alias SubModels =
    { scheduler_v2 : Maybe Scheduler_v2.Model
    , graph : Maybe Graph.Model
    , actor : Maybe ActorDetail.Model
    , directorDetail : Maybe DirectorDetail.Model
    , seatsIO : Maybe SeatsIO.Model
    , pricing : Maybe Pricing.Model
    , pricing_v2 : Maybe Pricing_v2.Model
    , userPage : Maybe UserPage.Model
    , userManagement : Maybe UserManagement.Model
    , ftmdb : Maybe FTMDB.Model
    , accountSettings : Maybe AccountSettings.Model
    , concessions : Maybe Concessions.Model
    , combos : Maybe Combos.Model
    , collections : Maybe Collections.Model
    , metabaseReport : Maybe Backoffice.MetabaseReport.Model
    , planning : Maybe Planning.Model
    , library : Maybe Library.Model
    , loyalty : Maybe Loyalty.Model
    , accountPlan : Maybe Accounting.Model
    , creditSales : Maybe CreditSales.Model
    }


type alias ModelData =
    { key : Nav.Key
    , page : Routes.Page
    , subModels : SubModels
    , store : Store
    , logoutModel : Maybe Cd.LogoutModel
    , url : Url.Url
    , menu : Menu.Model
    , toastsQueue : List Toast.ToastType
    , commons : Commons.Commons
    }


type Model
    = SessionOk ModelData
    | WaitingForSession { url : Url.Url, key : Nav.Key } (Maybe TenantId) (Nav.Key -> Cd.Session -> Commons.TenantsInfo -> List Location -> ModelData)
    | WaitingForTenants (Commons.TenantsInfo -> List Location -> ModelData)
    | SessionError Http.Error
    | TenantsError Utils.Http.CustomGraphqlError


type alias Flags =
    { now : Int
    , lastUsedTenantId : Maybe String
    , static : Commons.Static
    }


type alias TrackingUser =
    { email : String
    , firstName : String
    , lastName : String
    , tenantId : Maybe String
    , id : String
    }


storeGlue : Glue.Glue ModelData Store.Store SessionReadyMsg SessionReadyMsg
storeGlue =
    Glue.poly
        { get = .store
        , set = \store model -> { model | store = store }
        }


storeConfig : Store.Config SessionReadyMsg
storeConfig =
    { msg = StoreMsg
    , apiFetchError = ApiFetchError
    }


pricingGlue : Glue.Lazy.LazyGlue ModelData Pricing.Model SessionReadyMsg SessionReadyMsg
pricingGlue =
    Glue.poly
        { get = .subModels >> .pricing
        , set = \subModel ({ subModels } as model) -> { model | subModels = { subModels | pricing = subModel } }
        }


accountSettingsGlue : Glue.Lazy.LazyGlue ModelData AccountSettings.Model SessionReadyMsg SessionReadyMsg
accountSettingsGlue =
    Glue.poly
        { get = .subModels >> .accountSettings
        , set = \subModel ({ subModels } as model) -> { model | subModels = { subModels | accountSettings = subModel } }
        }


loyaltyGlue : Glue.Lazy.LazyGlue ModelData Loyalty.Model SessionReadyMsg SessionReadyMsg
loyaltyGlue =
    Glue.poly
        { get = .subModels >> .loyalty
        , set = \subModel ({ subModels } as model) -> { model | subModels = { subModels | loyalty = subModel } }
        }


accountPlanGlue : Glue.Lazy.LazyGlue ModelData Accounting.Model SessionReadyMsg SessionReadyMsg
accountPlanGlue =
    Glue.poly
        { get = .subModels >> .accountPlan
        , set = \subModel ({ subModels } as model) -> { model | subModels = { subModels | accountPlan = subModel } }
        }


creditSalesGlue : Glue.Lazy.LazyGlue ModelData CreditSales.Model SessionReadyMsg SessionReadyMsg
creditSalesGlue =
    Glue.poly
        { get = .subModels >> .creditSales
        , set = \subModel ({ subModels } as model) -> { model | subModels = { subModels | creditSales = subModel } }
        }


pricingConfig : Pricing.Config SessionReadyMsg
pricingConfig =
    Pricing.configure
        { msg = GotPricingMsg
        , storeAction = StoreAction
        }


concessionsConfig : Concessions.Config SessionReadyMsg
concessionsConfig =
    { msg = GotConcessionsMsg
    , storeAction = StoreAction
    }


accountPlanConfig : Accounting.Config SessionReadyMsg
accountPlanConfig =
    { msg = GotAccountPlanMsg
    , storeAction = StoreAction
    , toast = CreateToast
    }


combosConfig : Combos.Config SessionReadyMsg
combosConfig =
    { msg = GotCombosMsg
    , storeAction = StoreAction
    }


collectionsConfig : Collections.Config SessionReadyMsg
collectionsConfig =
    { msg = GotCollectionsMsg
    , storeAction = StoreAction
    }


loyaltyConfig : Loyalty.Config SessionReadyMsg
loyaltyConfig =
    { storeAction = StoreAction
    , createLoyaltyOwner = CreateLoyaltyOwner
    , toast = CreateToast
    }


creditSalesConfig : CreditSales.Config SessionReadyMsg
creditSalesConfig =
    { storeAction = StoreAction
    , createLoyaltyOwner = CreateLoyaltyOwner
    , toast = CreateToast
    }


emptySubmodels : SubModels
emptySubmodels =
    { scheduler_v2 = Nothing
    , graph = Nothing
    , actor = Nothing
    , directorDetail = Nothing
    , seatsIO = Nothing
    , pricing = Nothing
    , pricing_v2 = Nothing
    , userPage = Nothing
    , userManagement = Nothing
    , ftmdb = Nothing
    , accountSettings = Nothing
    , concessions = Nothing
    , combos = Nothing
    , collections = Nothing
    , metabaseReport = Nothing
    , planning = Nothing
    , library = Nothing
    , loyalty = Nothing
    , accountPlan = Nothing
    , creditSales = Nothing
    }


featuresToCheck : List Toggles.Feature
featuresToCheck =
    [ Toggles.DebugMode
    , Toggles.FTMDB
    , Toggles.OpeningHours
    , Toggles.ChartDesigner
    , Toggles.MoviePlanning
    , Toggles.ReportsConcessionSales
    , Toggles.Combos
    , Toggles.MaintenanceMode
    , Toggles.AccountingPlan
    , Toggles.Library
    , Toggles.LibraryMovies
    , Toggles.LibraryProductions
    , Toggles.Loyalty
    , Toggles.CreditSales
    , Toggles.Scheduler
    , Toggles.Pricing
    , Toggles.Concessions
    , Toggles.Reports
    , Toggles.Locations
    , Toggles.SchedulerListview
    , Toggles.PricingV2
    , Toggles.PricingPriceCards
    , Toggles.PricingSettlements
    ]


init : Flags -> Url.Url -> Nav.Key -> ( Model, Cmd Msg )
init flags url key =
    ( WaitingForSession { url = url, key = key }
        (Maybe.map Id.fromString flags.lastUsedTenantId)
        (\navKey user tenantsInfo locations ->
            let
                page : Routes.Page
                page =
                    Routes.fromUrl url
            in
            { page = page
            , subModels = emptySubmodels
            , store = Store.init tenantsInfo.tenant.settings.timezone locations
            , key = navKey
            , commons =
                { now = Time.millisToPosix flags.now
                , static = flags.static
                , tenantsInfo = tenantsInfo
                , user = user
                , enabledFeatures = Toggles.defaultSet
                }
            , url = url
            , logoutModel = Nothing
            , menu = Menu.init page
            , toastsQueue = []
            }
        )
    , Cf.toSession (RD.fromResult >> GotSession)
    )


port storeLastUsedTenantId : String -> Cmd msg


port setUserForTracking : TrackingUser -> Cmd msg



-- UPDATE


type SessionReadyMsg
    = LinkClicked Browser.UrlRequest
    | ApiFetchError Utils.Http.CustomGraphqlError
    | StoreMsg Store.Msg
    | StoreAction Store.StoreAction
    | GotLogoutUrl (Result Http.Error Cd.LogoutModel)
    | UrlChanged Url.Url
    | GotMetabaseMsg Backoffice.MetabaseReport.Msg
    | GotAccountSettingsMsg AccountSettings.Msg
    | GotSchedulerV2Msg Scheduler_v2.Msg
    | GotGraphMsg Graph.Msg
    | ActorDetailMsg ActorDetail.Msg
    | DirectorDetailMsg DirectorDetail.Msg
    | GotSeatsIOMsg SeatsIO.Msg
    | GotPricingMsg Pricing.Msg
    | GotPricingV2Msg Pricing_v2.Msg
    | GotUserPageMsg UserPage.Msg
    | GotUserManagementMsg UserManagement.Msg
    | MenuMsg Menu.Msg
    | GotFeatureToggle ( Toggles.Feature, Bool )
    | TogglesWasUpdated
    | ExtendSession
    | GotSessionForExtend (Result Http.Error Cd.Session)
    | SaveOpeningHours ExtendedOpeningHoursSettings
    | UpdatedTenant (Result (GH.Error Tenant) Tenant)
    | ChangeTenant TenantId String
    | GotTenant (Result (GH.Error ( Tenant, List Location )) ( Tenant, List Location ))
    | ClearOldestToast
    | GotConcessionsMsg Concessions.Msg
    | GotAccountPlanMsg Accounting.Msg
    | GotCombosMsg Combos.Msg
    | GotCollectionsMsg Collections.Msg
    | GotPlanningMsg Planning.Msg
    | GotLibraryMsg Library.Msg
    | GotLoyaltyMsg Loyalty.Msg
    | GotCreditSalesMsg CreditSales.Msg
    | CreateLoyaltyOwner
    | CreateToast Toast.ToastType


type Msg
    = GotSession (RD.WebData Cd.Session)
    | GotTenantsInfo (Result Utils.Http.CustomGraphqlError ( List TenantSlim.Tenant, ( Tenant, List Location ) ))
    | SessionReady SessionReadyMsg


handleTenantChanged : TenantId -> ( ModelData, Cmd SessionReadyMsg ) -> ( ModelData, Cmd SessionReadyMsg )
handleTenantChanged tenantId ( model, cmd ) =
    let
        user : Cd.Session
        user =
            model.commons.user

        cmds : Cmd SessionReadyMsg
        cmds =
            Cmd.batch
                [ cmd
                , setUserForTracking
                    (TrackingUser user.identity.traits.email
                        user.identity.traits.first_name
                        user.identity.traits.last_name
                        (Just <| Id.unwrap tenantId)
                        user.identity.id
                    )
                ]
    in
    if tenantId == model.commons.tenantsInfo.tenant.id then
        ( model, cmds )

    else
        let
            maybeStoreLastUsedTenantId : Cmd msg
            maybeStoreLastUsedTenantId =
                Id.unwrap tenantId |> storeLastUsedTenantId

            subModels : SubModels
            subModels =
                model.subModels
        in
        ( { model | subModels = { subModels | loyalty = Nothing } }
        , Cmd.batch
            [ cmds
            , maybeStoreLastUsedTenantId
            , Tenant.queryTenant tenantId
                |> GH.queryRequest ApiConfig.graphqlEndpoint
                |> GH.withCredentials
                |> GH.send GotTenant
            ]
        )


initPage : ModelData -> ( ModelData, Cmd SessionReadyMsg )
initPage model =
    let
        initSubModule :
            (SubModels -> Maybe subModel)
            -> (SubModels -> ( SubModels, Cmd SessionReadyMsg ))
            -> (subModel -> SubModels -> ( SubModels, Cmd SessionReadyMsg ))
            -> ( ModelData, Cmd SessionReadyMsg )
        initSubModule getter initFn reInitFn =
            case getter model.subModels of
                Just subModel ->
                    let
                        ( newSubmodels, cmds ) =
                            reInitFn subModel model.subModels
                    in
                    ( { model | subModels = newSubmodels }, cmds )

                Nothing ->
                    let
                        ( newSubmodels, cmds ) =
                            initFn model.subModels
                    in
                    ( { model | subModels = newSubmodels }, cmds )
    in
    case model.page of
        Scheduler_v2 ->
            initSubModule .scheduler_v2
                (\sModels ->
                    Scheduler_v2.init model.key model.commons model.store
                        |> Tuple.mapBoth (\m -> { sModels | scheduler_v2 = Just m }) (Cmd.map GotSchedulerV2Msg)
                )
                (always Cmd.pure)

        Pricing ->
            initSubModule .pricing
                (\sModels ->
                    Pricing.init model.commons pricingConfig
                        |> Tuple.mapFirst (\m -> { sModels | pricing = Just m })
                )
                (always Cmd.pure)

        Pricing_v2 subPage ->
            initSubModule .pricing_v2
                (\sModels ->
                    Pricing_v2.init model.commons model.key subPage
                        |> Tuple.mapBoth (\m -> { sModels | pricing_v2 = Just m }) (Cmd.map GotPricingV2Msg)
                )
                (always Cmd.pure)

        Graph ->
            initSubModule .graph
                (\sModels ->
                    Graph.init model.key
                        |> Tuple.mapBoth (\m -> { sModels | graph = Just m }) (Cmd.map GotGraphMsg)
                )
                (always Cmd.pure)

        Actor int ->
            initSubModule .actor
                (\sModels ->
                    ActorDetail.init int model.key
                        |> Tuple.mapBoth (\m -> { sModels | actor = Just m }) (Cmd.map ActorDetailMsg)
                )
                (always Cmd.pure)

        Director id ->
            initSubModule .directorDetail
                (\sModels ->
                    DirectorDetail.init id model.key
                        |> Tuple.mapBoth (\m -> { sModels | directorDetail = Just m }) (Cmd.map DirectorDetailMsg)
                )
                (always Cmd.pure)

        SeatsIO ->
            initSubModule .seatsIO
                (\sModels ->
                    SeatsIO.init model.commons model.key
                        |> Tuple.mapBoth (\m -> { sModels | seatsIO = Just m }) (Cmd.map GotSeatsIOMsg)
                )
                (always Cmd.pure)

        UserPage ->
            initSubModule .userPage
                (\sModels ->
                    UserPage.init model.key
                        |> Tuple.mapBoth (\m -> { sModels | userPage = Just m }) (Cmd.map GotUserPageMsg)
                )
                (always Cmd.pure)

        UserManagement ->
            initSubModule .userManagement
                (\sModels ->
                    UserManagement.init model.commons
                        |> Tuple.mapBoth (\m -> { sModels | userManagement = Just m }) (Cmd.map GotUserManagementMsg)
                )
                (always Cmd.pure)

        AccountSettings id ->
            initSubModule .accountSettings
                (\sModels ->
                    AccountSettings.init
                        model.commons
                        model.key
                        id
                        { msg = GotAccountSettingsMsg, storeAction = StoreAction, saveOpeningHours = SaveOpeningHours }
                        |> Tuple.mapFirst (\m -> { sModels | accountSettings = Just m })
                )
                (always Cmd.pure)

        Accounting subPage ->
            initSubModule .accountPlan
                (\sModels ->
                    Accounting.init model.commons.tenantsInfo.tenant model.key accountPlanConfig subPage
                        |> Tuple.mapFirst (\m -> { sModels | accountPlan = Just m })
                )
                (always Cmd.pure)

        Concessions concessionMenu ->
            case concessionMenu of
                Routes.Products ->
                    initSubModule .concessions
                        (\sModels ->
                            Concessions.init
                                model.commons
                                concessionsConfig
                                model.key
                                |> Tuple.mapFirst (\m -> { sModels | concessions = Just m })
                        )
                        (\sModel sModels ->
                            Concessions.reInit sModel model.commons.tenantsInfo.tenant concessionsConfig
                                |> Tuple.mapFirst (\m -> { sModels | concessions = Just m })
                        )

                Routes.Menu ->
                    initSubModule .combos
                        (\sModels ->
                            Combos.init
                                model.commons
                                combosConfig
                                model.key
                                |> Tuple.mapFirst (\m -> { sModels | combos = Just m })
                        )
                        (\sModel sModels ->
                            Combos.reInit sModel model.commons.tenantsInfo.tenant combosConfig
                                |> Tuple.mapFirst (\m -> { sModels | combos = Just m })
                        )

                Routes.Collections ->
                    initSubModule .collections
                        (\sModels ->
                            Collections.init
                                model.commons
                                collectionsConfig
                                model.key
                                |> Tuple.mapFirst (\m -> { sModels | collections = Just m })
                        )
                        (always Cmd.pure)

        Planning ->
            initSubModule .planning
                (\sModels ->
                    Planning.init model.commons model.key
                        |> Tuple.mapBoth (\m -> { sModels | planning = Just m }) (Cmd.map GotPlanningMsg)
                )
                (always Cmd.pure)

        Loyalty ->
            initSubModule .loyalty
                (\sModels ->
                    Loyalty.init model.commons loyaltyConfig
                        |> Tuple.mapFirst (\m -> { sModels | loyalty = Just m })
                )
                (always Cmd.pure)

        CreditSales ->
            initSubModule .creditSales
                (\sModels ->
                    CreditSales.init model.commons creditSalesConfig
                        |> Tuple.mapFirst (\m -> { sModels | creditSales = Just m })
                )
                (always Cmd.pure)

        MetabaseReport subReport ->
            initSubModule .metabaseReport
                (\sModels ->
                    Backoffice.MetabaseReport.init model.commons subReport
                        |> Tuple.mapBoth (\m -> { sModels | metabaseReport = Just m }) (Cmd.map GotMetabaseMsg)
                )
                (\sModel sModels ->
                    Backoffice.MetabaseReport.reInit subReport sModel
                        |> Tuple.mapBoth (\m -> { sModels | metabaseReport = Just m }) (Cmd.map GotMetabaseMsg)
                )

        Library subPage ->
            initSubModule .library
                (\sModels ->
                    Library.init model.commons model.key subPage
                        |> Tuple.mapBoth (\m -> { sModels | library = Just m }) (Cmd.map GotLibraryMsg)
                )
                (always Cmd.pure)


scheduleExtendSession : Commons.Commons -> (SessionReadyMsg -> a) -> Cmd a
scheduleExtendSession { now, user } toMsg =
    Process.sleep
        ((Time.posixToMillis user.expiresAt - Time.posixToMillis now - (30 * 1000))
            -- call every hour or sooner if it' s going to expire
            |> min (60 * 60 * 1000)
            |> toFloat
        )
        |> Task.attempt (ExtendSession |> toMsg |> always)


toastDuration : Float
toastDuration =
    5000


createToast : Toast.ToastType -> ModelData -> ( ModelData, Cmd SessionReadyMsg )
createToast toast model =
    { model | toastsQueue = toast :: model.toastsQueue }
        |> Cmd.with
            (Process.sleep toastDuration
                |> Task.attempt (always ClearOldestToast)
            )


onStoreUpdate : Store.Msg -> ModelData -> ( ModelData, Cmd SessionReadyMsg )
onStoreUpdate storeMsg model =
    case storeMsg of
        Store.NoOp ->
            Cmd.pure model

        Store.ApiFetchError _ err ->
            createToast (Toast.TypeError ("Something went wrong: " ++ Utils.Http.customGraphqlErrorToString err)) model

        Store.GotPriceCards _ ->
            Cmd.pure model

        Store.GotUpdatedPriceCard _ _ ->
            Cmd.pure model

        Store.GotCreatedPriceCard _ _ ->
            Cmd.pure model

        Store.GotTicketTypes _ ->
            Cmd.pure model

        Store.GotScreeningSeatsInfo _ _ ->
            Cmd.pure model

        Store.DelayedMsg _ _ ->
            Cmd.pure model

        Store.RetryGetScreeningSeatsInfo _ _ _ ->
            Cmd.pure model

        Store.LocationCreated _ _ ->
            Cmd.pure model

        Store.LocationUpdated _ _ _ ->
            Cmd.pure model

        Store.LocationUpdatedDefaultSeatchart _ ->
            Cmd.pure model

        Store.GotSeatCharts _ ->
            Cmd.pure model

        Store.GotArchivedStatus _ ->
            Cmd.pure model

        Store.ComboCollectionUpdated _ ->
            Cmd.pure model

        Store.GotConcessionsOwner _ ->
            Cmd.pure model

        Store.SellableUpdated _ ->
            Cmd.pure model

        Store.SellableArchived _ ->
            Cmd.pure model

        Store.SellableCreated _ ->
            Cmd.pure model

        Store.ComboUpdated _ ->
            Cmd.pure model

        Store.ComboSaved _ ->
            Cmd.pure model

        Store.ComboArchived _ ->
            Cmd.pure model

        Store.ComboCollectionSaved _ ->
            Cmd.pure model

        Store.ComboCollectionArchived _ ->
            Cmd.pure model

        Store.GotDiscountCards _ ->
            Cmd.pure model

        Store.DiscountCardCreated _ ->
            Cmd.pure model

        Store.DiscountCardEdited _ ->
            Cmd.pure model

        Store.DiscountCardArchived _ ->
            Cmd.pure model

        Store.UnlimitedPassCreated _ ->
            Cmd.pure model

        Store.GotUnlimitedPasses _ ->
            Cmd.pure model

        Store.GotAccountingPlanData _ ->
            Cmd.pure model

        Store.UnlimitedPassUpdated _ ->
            Cmd.pure model

        Store.UnlimitedPassArchived _ ->
            Cmd.pure model

        Store.GotAccountPlanOwner _ ->
            Cmd.pure model

        Store.AccountCreated _ ->
            Cmd.pure model

        Store.AccountUpdated _ ->
            Cmd.pure model

        Store.AccountArchived _ ->
            Cmd.pure model

        Store.AccountProductCreated _ ->
            Cmd.pure model

        Store.AccountProductUpdated _ ->
            Cmd.pure model

        Store.AccountProductArchived _ ->
            Cmd.pure model

        Store.GotCreditSales _ ->
            Cmd.pure model

        Store.CreditSaleCreated _ ->
            Cmd.pure model

        Store.CreditSaleUpdated _ ->
            Cmd.pure model

        Store.CreditSaleArchived _ ->
            Cmd.pure model


update : Msg -> Model -> ( Model, Cmd Msg )
update msg_ model_ =
    let
        updateSubmodule :
            ModelData
            -> (SubModels -> Maybe subModel)
            -> (subModel -> SubModels -> SubModels)
            -> (subModel -> ( subModel, Cmd msg ))
            -> (msg -> SessionReadyMsg)
            -> ( ModelData, Cmd SessionReadyMsg )
        updateSubmodule model get set updateFn toMsg =
            case get model.subModels of
                Just subModel ->
                    updateFn subModel
                        |> Tuple.mapBoth (\m -> { model | subModels = set m model.subModels }) (Cmd.map toMsg)

                Nothing ->
                    Cmd.pure model
    in
    case msg_ of
        GotSession sessionResponse ->
            case model_ of
                WaitingForSession { url, key } maybeTenantId toModelData ->
                    case sessionResponse of
                        RD.Failure e ->
                            if Cf.httpErrorIs401 e then
                                Cf.redirectToLogin url model_

                            else
                                ( SessionError e, Cmd.none )

                        RD.Success user ->
                            let
                                isOverlord : Bool
                                isOverlord =
                                    Maybe.unwrap False .overlord user.identity.metadataPublic

                                gueryTenantTask : TenantId -> Task.Task Utils.Http.CustomGraphqlError ( Tenant, List Location )
                                gueryTenantTask tenantId =
                                    Tenant.queryTenant tenantId
                                        |> GH.queryRequest ApiConfig.graphqlEndpoint
                                        |> GH.withCredentials
                                        |> GH.toTask
                                        |> Task.mapError Utils.Http.toCustomGraphqlError

                                tenantsRequestsTask : Task.Task Utils.Http.CustomGraphqlError ( List TenantSlim.Tenant, ( Tenant, List Location ) )
                                tenantsRequestsTask =
                                    (if isOverlord then
                                        TenantSlim.requestTenantsTask identity Nothing

                                     else
                                        TenantsByUser.querySlimTask identity
                                    )
                                        |> Task.mapError Utils.Http.toCustomGraphqlError
                                        |> Task.andThen
                                            (\tenants ->
                                                let
                                                    -- validateTenant will return nothing if input-id does not exist in the tenantResponse
                                                    validateTenant : Maybe TenantId -> Maybe TenantId
                                                    validateTenant =
                                                        Maybe.andThen
                                                            (\tenantId ->
                                                                List.find (.id >> (==) tenantId) tenants
                                                                    |> Maybe.map .id
                                                            )

                                                    -- if model.tenantId is still nothing: default to first in list of tenants (this might happen if user has no tenant-roles, but only overlord role)
                                                    newTenantId : Maybe TenantId
                                                    newTenantId =
                                                        Maybe.or (validateTenant maybeTenantId) (List.head tenants |> Maybe.map .id)
                                                in
                                                Maybe.map
                                                    (\tenantId ->
                                                        gueryTenantTask tenantId
                                                            |> Task.map (Tuple.pair tenants)
                                                    )
                                                    newTenantId
                                                    -- TODO use some custom error for point issue with missing tenantId
                                                    |> Maybe.withDefault
                                                        (Task.fail (Utils.Http.OtherError []))
                                            )
                            in
                            ( WaitingForTenants (toModelData key user)
                            , tenantsRequestsTask
                                |> Task.attempt GotTenantsInfo
                            )

                        RD.Loading ->
                            ( model_, Cmd.none )

                        RD.NotAsked ->
                            ( model_, Cmd.none )

                WaitingForTenants _ ->
                    ( model_
                    , ErrorReporting.submitErrorReport
                        { fileName = "Backoffice.elm"
                        , functionName = "update"
                        , errorLevel = ErrorReporting.Error
                        , title = "Got session in wrong time"
                        , description = "Got session while waiting for tenants response"
                        }
                    )

                SessionError _ ->
                    ( model_, Cmd.none )

                TenantsError _ ->
                    ( model_, Cmd.none )

                SessionOk _ ->
                    -- Can we ignore this case? Or is it worth to renew stored data? Either the way this should not happen.
                    ( model_, Cmd.none )

        GotTenantsInfo (Ok ( tenants, ( tenant, locations ) )) ->
            case model_ of
                WaitingForTenants toModelData ->
                    let
                        ( modelWithSessionResponse, cmds ) =
                            toModelData { tenants = tenants, tenant = tenant } locations
                                |> initPage
                                |> handleTenantChanged tenant.id

                        userIdentifier : String
                        userIdentifier =
                            modelWithSessionResponse.commons.user.identity.traits.email

                        batchTogglesCheck : List Toggles.Feature -> Cmd msg
                        batchTogglesCheck features =
                            List.map (Toggles.checkAccessForUser userIdentifier tenant.ref) features
                                |> Cmd.batch

                        checkToggles : Cmd msg
                        checkToggles =
                            batchTogglesCheck featuresToCheck
                    in
                    ( SessionOk modelWithSessionResponse
                    , Cmd.batch
                        [ Cmd.map SessionReady cmds
                        , Cf.getLogoutModel (GotLogoutUrl >> SessionReady)
                        , checkToggles
                        , scheduleExtendSession modelWithSessionResponse.commons SessionReady
                        ]
                    )

                WaitingForSession _ _ _ ->
                    ( model_, Cmd.none )

                SessionError _ ->
                    ( model_, Cmd.none )

                TenantsError _ ->
                    ( model_, Cmd.none )

                SessionOk _ ->
                    ( model_, Cmd.none )

        GotTenantsInfo (Err err) ->
            ( TenantsError err, Cmd.none )

        SessionReady msg ->
            case model_ of
                WaitingForSession _ _ _ ->
                    ( model_
                    , ErrorReporting.submitErrorReport
                        { fileName = "Backoffice.elm"
                        , functionName = "update"
                        , errorLevel = ErrorReporting.Error
                        , title = "SessionReady msg was triggered in wrong time"
                        , description = "SessionReady msg was triggered without Session beeing actually ready"
                        }
                    )

                WaitingForTenants _ ->
                    ( model_
                    , ErrorReporting.submitErrorReport
                        { fileName = "Backoffice.elm"
                        , functionName = "update"
                        , errorLevel = ErrorReporting.Error
                        , title = "SessionReady msg was triggered in wrong time"
                        , description = "SessionReady msg was triggered before tenants being loaded and actually ready"
                        }
                    )

                SessionError _ ->
                    ( model_, Cmd.none )

                TenantsError _ ->
                    ( model_, Cmd.none )

                SessionOk ({ commons } as model) ->
                    Tuple.mapBoth SessionOk (Cmd.map SessionReady) <|
                        case msg of
                            ApiFetchError error ->
                                case error of
                                    Utils.Http.OtherError _ ->
                                        Cmd.pure model

                                    Utils.Http.Unauthenticated ->
                                        Cf.redirectToLogin model.url model

                                    Utils.Http.HttError e ->
                                        if Utils.Http.graphqlHttpErrorIs401 e then
                                            Cf.redirectToLogin model.url model

                                        else
                                            ( model, Cmd.none )

                                    Utils.Http.RegularHttpError _ ->
                                        Cmd.pure model

                            StoreMsg subSmg ->
                                let
                                    ( newModel, cmds ) =
                                        Cmd.pure model
                                            |> Glue.update storeGlue (Store.update storeConfig) subSmg
                                in
                                ( newModel, cmds )
                                    |> Glue.Lazy.updateWith pricingGlue
                                        (Pricing.onStoreUpdate subSmg)
                                    |> Glue.Lazy.updateWith accountSettingsGlue
                                        (AccountSettings.onStoreUpdate subSmg)
                                    |> Glue.Lazy.updateWith loyaltyGlue
                                        (Loyalty.onStoreUpdate loyaltyConfig subSmg)
                                    |> Glue.Lazy.updateWith accountPlanGlue
                                        (Accounting.onStoreUpdate accountPlanConfig subSmg commons.now)
                                    |> Glue.Lazy.updateWith creditSalesGlue
                                        (CreditSales.onStoreUpdate commons creditSalesConfig subSmg)
                                    |> Glue.updateWith Glue.id (onStoreUpdate subSmg)

                            StoreAction action ->
                                Cmd.pure model
                                    |> Glue.updateWith storeGlue (Store.fetch storeConfig action)

                            GotTenant (Ok ( tenant, locations )) ->
                                let
                                    { tenantsInfo } =
                                        commons
                                in
                                { model
                                    | commons =
                                        { commons
                                            | tenantsInfo = { tenantsInfo | tenant = tenant }
                                        }
                                    , menu = Menu.tenantSwitched model.menu
                                    , store = Store.init tenant.settings.timezone locations
                                    , subModels = emptySubmodels
                                }
                                    |> Cmd.pure
                                    |> Glue.updateWith Glue.id initPage
                                    |> Glue.Lazy.updateWith
                                        (Glue.poly
                                            { get = .subModels >> .userManagement
                                            , set =
                                                \subModel ({ subModels } as m) ->
                                                    { m | subModels = { subModels | userManagement = subModel } }
                                            }
                                        )
                                        (Cmd.pure >> UserManagement.tenantChanged tenant model.commons.tenantsInfo.tenants >> Glue.map GotUserManagementMsg)

                            GotTenant (Err err) ->
                                ( model
                                , ErrorReporting.submitErrorReport
                                    { fileName = "Backoffice.elm"
                                    , functionName = "GotTenant"
                                    , errorLevel = ErrorReporting.Error
                                    , title = "Switch tenant failed"
                                    , description = Backoffice.Utils.GraphQL.graphQLErrorToString err
                                    }
                                )

                            ChangeTenant newTenantId ref ->
                                ( model, Toggles.setTenant ref )
                                    |> handleTenantChanged newTenantId

                            GotFeatureToggle ( feature, enabled ) ->
                                let
                                    newModel : ModelData
                                    newModel =
                                        { model
                                            | commons =
                                                { commons
                                                    | enabledFeatures = Toggles.updateFeatureSet ( feature, enabled ) commons.enabledFeatures
                                                }
                                        }
                                in
                                ( newModel, Cmd.none )

                            TogglesWasUpdated ->
                                let
                                    userIdentifier : String
                                    userIdentifier =
                                        model.commons.user.identity.traits.email

                                    batchTogglesCheck : List Toggles.Feature -> Cmd msg
                                    batchTogglesCheck features =
                                        List.map (Toggles.checkAccessForUser userIdentifier model.commons.tenantsInfo.tenant.ref) features
                                            |> Cmd.batch

                                    checkToggles : Cmd msg
                                    checkToggles =
                                        batchTogglesCheck featuresToCheck
                                in
                                ( model, checkToggles )

                            GotLogoutUrl x ->
                                case x of
                                    Result.Ok s ->
                                        ( { model | logoutModel = Just s }, Cmd.none )

                                    _ ->
                                        ( model, Cmd.none )

                            LinkClicked urlRequest ->
                                case urlRequest of
                                    Browser.Internal url ->
                                        if String.startsWith "/api/.ory" url.path then
                                            ( model, Nav.load (Url.toString url) )

                                        else
                                            ( { model | page = Routes.fromUrl url, url = url }, Nav.pushUrl model.key (Url.toString url) )

                                    Browser.External href ->
                                        ( model, Nav.load href )

                            UrlChanged url ->
                                initPage { model | page = Routes.fromUrl url }
                                    |> Glue.updateWith Glue.id
                                        (\m ->
                                            case Routes.fromUrl url of
                                                Library subPage ->
                                                    updateSubmodule m
                                                        .library
                                                        (\library subModels -> { subModels | library = Just library })
                                                        (Library.onUrlChange commons subPage)
                                                        GotLibraryMsg

                                                AccountSettings subPage ->
                                                    updateSubmodule m
                                                        .accountSettings
                                                        (\accountSettings subModels -> { subModels | accountSettings = Just accountSettings })
                                                        (AccountSettings.onUrlChange subPage)
                                                        GotAccountSettingsMsg

                                                Accounting subPage ->
                                                    updateSubmodule m
                                                        .accountPlan
                                                        (\accountPlan subModels -> { subModels | accountPlan = Just accountPlan })
                                                        (Accounting.onUrlChange model.store model.commons.now model.commons.tenantsInfo.tenant subPage)
                                                        GotAccountPlanMsg

                                                Pricing_v2 subPage ->
                                                    updateSubmodule m
                                                        .pricing_v2
                                                        (\pricing subModels -> { subModels | pricing_v2 = Just pricing })
                                                        (Pricing_v2.onUrlChange model.commons subPage)
                                                        GotPricingV2Msg

                                                _ ->
                                                    Cmd.pure m
                                        )

                            GotSchedulerV2Msg subMsg ->
                                let
                                    { subModels } =
                                        model
                                in
                                case subModels.scheduler_v2 of
                                    Just subModel ->
                                        let
                                            ( newCommons, newSchedulerModel, schedulerCmd ) =
                                                Scheduler_v2.update model.commons subMsg subModel model.store
                                        in
                                        ( { model
                                            | commons = newCommons
                                            , subModels = { subModels | scheduler_v2 = Just newSchedulerModel }
                                          }
                                        , Cmd.map GotSchedulerV2Msg <| schedulerCmd
                                        )

                                    Nothing ->
                                        ( model, Cmd.none )

                            GotGraphMsg subMsg ->
                                updateSubmodule model
                                    .graph
                                    (\m subModels -> { subModels | graph = Just m })
                                    (Graph.update subMsg)
                                    GotGraphMsg

                            ActorDetailMsg subMsg ->
                                updateSubmodule model
                                    .actor
                                    (\m subModels -> { subModels | actor = Just m })
                                    (ActorDetail.update subMsg)
                                    ActorDetailMsg

                            DirectorDetailMsg subMsg ->
                                updateSubmodule model
                                    .directorDetail
                                    (\m subModels -> { subModels | directorDetail = Just m })
                                    (DirectorDetail.update subMsg)
                                    DirectorDetailMsg

                            GotSeatsIOMsg subMsg ->
                                updateSubmodule model
                                    .seatsIO
                                    (\m subModels -> { subModels | seatsIO = Just m })
                                    (SeatsIO.update subMsg)
                                    GotSeatsIOMsg

                            GotPricingMsg subMsg ->
                                updateSubmodule model
                                    .pricing
                                    (\m subModels -> { subModels | pricing = Just m })
                                    (Pricing.update pricingConfig model.commons subMsg)
                                    identity

                            GotPricingV2Msg subMsg ->
                                updateSubmodule model
                                    .pricing_v2
                                    (\m subModels -> { subModels | pricing_v2 = Just m })
                                    (Pricing_v2.update commons subMsg)
                                    GotPricingV2Msg

                            GotUserPageMsg subMsg ->
                                updateSubmodule model
                                    .userPage
                                    (\m subModels -> { subModels | userPage = Just m })
                                    (UserPage.update subMsg)
                                    GotUserPageMsg

                            GotUserManagementMsg subMsg ->
                                updateSubmodule model
                                    .userManagement
                                    (\m subModels -> { subModels | userManagement = Just m })
                                    (UserManagement.update model.commons subMsg)
                                    GotUserManagementMsg

                            GotConcessionsMsg subMsg ->
                                updateSubmodule model
                                    .concessions
                                    (\m subModels -> { subModels | concessions = Just m })
                                    (Concessions.update model.store model.commons { msg = GotConcessionsMsg, storeAction = StoreAction } subMsg)
                                    identity

                            GotAccountPlanMsg subMsg ->
                                updateSubmodule
                                    model
                                    .accountPlan
                                    (\m subModels -> { subModels | accountPlan = Just m })
                                    (Accounting.update model.store accountPlanConfig model.commons.tenantsInfo.tenant model.commons.now subMsg)
                                    identity

                            GotCombosMsg subMsg ->
                                updateSubmodule model
                                    .combos
                                    (\m subModels -> { subModels | combos = Just m })
                                    (Combos.update model.commons model.store { msg = GotCombosMsg, storeAction = StoreAction } subMsg)
                                    identity

                            GotCollectionsMsg subMsg ->
                                updateSubmodule model
                                    .collections
                                    (\m subModels -> { subModels | collections = Just m })
                                    (Collections.update model.commons model.store { msg = GotCollectionsMsg, storeAction = StoreAction } subMsg)
                                    identity

                            GotPlanningMsg subMsg ->
                                updateSubmodule model
                                    .planning
                                    (\m subModels -> { subModels | planning = Just m })
                                    (Planning.update subMsg)
                                    GotPlanningMsg

                            MenuMsg subMsg ->
                                let
                                    ( m, subCmd ) =
                                        Menu.update { changeTenant = ChangeTenant } subMsg model.menu
                                in
                                ( { model
                                    | menu = m
                                  }
                                , subCmd
                                )

                            GotAccountSettingsMsg subMsg ->
                                updateSubmodule model
                                    .accountSettings
                                    (\m subModels -> { subModels | accountSettings = Just m })
                                    (AccountSettings.update commons { msg = GotAccountSettingsMsg, storeAction = StoreAction, saveOpeningHours = SaveOpeningHours } subMsg)
                                    identity

                            GotMetabaseMsg subMsg ->
                                updateSubmodule model
                                    .metabaseReport
                                    (\m subModels -> { subModels | metabaseReport = Just m })
                                    (Backoffice.MetabaseReport.update model.commons subMsg)
                                    GotMetabaseMsg

                            ExtendSession ->
                                ( model
                                , Cf.extendSession GotSessionForExtend
                                )

                            GotSessionForExtend sessionResponse ->
                                case sessionResponse of
                                    Err e ->
                                        if Cf.httpErrorIs401 e then
                                            Cf.redirectToLogin model.url model

                                        else
                                            ( model, Cmd.none )

                                    Ok user ->
                                        let
                                            newModel : ModelData
                                            newModel =
                                                { model | commons = { commons | user = user } }
                                        in
                                        ( newModel, scheduleExtendSession commons identity )

                            SaveOpeningHours newOpeningHours ->
                                let
                                    { tenantsInfo } =
                                        commons

                                    { tenant } =
                                        tenantsInfo

                                    settings : Tenant.Settings
                                    settings =
                                        tenant.settings

                                    newTenant : Tenant
                                    newTenant =
                                        { tenant | settings = { settings | extendedOpeningHoursSettings = newOpeningHours } }
                                in
                                ( { model | commons = { commons | tenantsInfo = { tenantsInfo | tenant = newTenant } } }
                                , Tenant.updateTenantOpeningHours newTenant
                                    |> GH.mutationRequest ApiConfig.graphqlEndpoint
                                    |> GH.withCredentials
                                    |> GH.send UpdatedTenant
                                )

                            UpdatedTenant (Ok newTenant) ->
                                let
                                    { tenantsInfo } =
                                        commons
                                in
                                Cmd.pure { model | commons = { commons | tenantsInfo = { tenantsInfo | tenant = newTenant } } }

                            UpdatedTenant (Err err) ->
                                -- TODO show some error modal/notification to user
                                ( model
                                , ErrorReporting.submitErrorReport
                                    { fileName = "Backoffice.elm"
                                    , functionName = "update UpdatedTenant"
                                    , errorLevel = ErrorReporting.Error
                                    , title = "Saving tenant failed"
                                    , description = Backoffice.Utils.GraphQL.graphQLErrorToString err
                                    }
                                )

                            ClearOldestToast ->
                                Cmd.pure
                                    { model
                                        | toastsQueue =
                                            List.removeAt (List.length model.toastsQueue - 1) model.toastsQueue
                                    }

                            GotLibraryMsg subMsg ->
                                updateSubmodule model
                                    .library
                                    (\m subModels -> { subModels | library = Just m })
                                    (Library.update model.commons subMsg)
                                    GotLibraryMsg

                            GotLoyaltyMsg subMsg ->
                                updateSubmodule model
                                    .loyalty
                                    (\m subModels -> { subModels | loyalty = Just m })
                                    (Loyalty.update model.commons loyaltyConfig model.store subMsg)
                                    identity

                            GotCreditSalesMsg subMsg ->
                                updateSubmodule model
                                    .creditSales
                                    (\m subModels -> { subModels | creditSales = Just m })
                                    (CreditSales.update model.commons creditSalesConfig model.store subMsg)
                                    identity

                            CreateLoyaltyOwner ->
                                ( model
                                , Tenant.createLoyaltyOwner model.commons.tenantsInfo.tenant
                                    |> GH.mutationRequest ApiConfig.graphqlEndpoint
                                    |> GH.withCredentials
                                    |> GH.send
                                        (\newOwnersResult ->
                                            case newOwnersResult of
                                                Ok newOwners ->
                                                    let
                                                        tenant : Tenant
                                                        tenant =
                                                            model.commons.tenantsInfo.tenant

                                                        newTenant : Tenant
                                                        newTenant =
                                                            List.foldl
                                                                (\newOwner t ->
                                                                    if t.id == newOwner.tenantId then
                                                                        { t | loyaltyOwner = Just newOwner }

                                                                    else
                                                                        t
                                                                )
                                                                tenant
                                                                newOwners
                                                    in
                                                    UpdatedTenant <| Ok newTenant

                                                Err _ ->
                                                    CreateToast <| Toast.TypeError "Could not create loyalty owner"
                                        )
                                )

                            CreateToast toast ->
                                createToast toast model



-- SUBSCRIPTIONS


subscriptions : Model -> Sub Msg
subscriptions model =
    let
        subModelSubscriptions : ModelData -> Sub SessionReadyMsg
        subModelSubscriptions model_ =
            case model_.page of
                Scheduler_v2 ->
                    Maybe.unwrap Sub.none (Scheduler_v2.subscriptions >> Sub.map GotSchedulerV2Msg) model_.subModels.scheduler_v2

                Graph ->
                    Sub.none

                Actor _ ->
                    Sub.none

                Director _ ->
                    Sub.none

                SeatsIO ->
                    Sub.none

                Pricing ->
                    Maybe.unwrap Sub.none (Pricing.subscriptions >> Sub.map GotPricingMsg) model_.subModels.pricing

                Pricing_v2 _ ->
                    Maybe.unwrap Sub.none (Pricing_v2.subscriptions >> Sub.map GotPricingV2Msg) model_.subModels.pricing_v2

                UserPage ->
                    Sub.none

                UserManagement ->
                    Sub.none

                Accounting _ ->
                    Maybe.unwrap Sub.none (Accounting.subscriptions >> Sub.map GotAccountPlanMsg) model_.subModels.accountPlan

                AccountSettings _ ->
                    Sub.none

                Concessions _ ->
                    Maybe.unwrap Sub.none (Concessions.subscriptions >> Sub.map GotConcessionsMsg) model_.subModels.concessions

                Planning ->
                    Sub.none

                MetabaseReport _ ->
                    Sub.none

                Library _ ->
                    Sub.none

                Loyalty ->
                    Sub.none

                CreditSales ->
                    Sub.none
    in
    Sub.batch
        [ case model of
            WaitingForSession _ _ _ ->
                Sub.none

            SessionError _ ->
                Sub.none

            WaitingForTenants _ ->
                Sub.none

            TenantsError _ ->
                Sub.none

            SessionOk model_ ->
                subModelSubscriptions model_
                    |> Sub.map SessionReady
        , Toggles.syncToggleState (GotFeatureToggle >> SessionReady)
        , processUpdatedFeatures (TogglesWasUpdated |> SessionReady |> always)
        ]


port processUpdatedFeatures : (() -> msg) -> Sub msg



-- VIEW


view : Model -> Document Msg
view model =
    { title = "Backoffice"
    , body =
        [ Html.Styled.toUnstyled <|
            Css.Global.global
                (List.fastConcat
                    [ Tw.globalStyles
                    , [ Css.Global.selector "body" [ Tw.font_inter, Tw.text_color Theme.text_primary ]
                      ]
                    ]
                )
        , Html.Styled.toUnstyled <| viewBody model
        ]
    }


viewBody : Model -> Html Msg
viewBody model_ =
    case model_ of
        WaitingForSession _ _ _ ->
            Html.Styled.div [ Attr.css [ Tw.m_8, Tw.mt_0, Tw.pt_60 ] ]
                [ viewSpinnerWithText "Loading user"
                ]

        WaitingForTenants _ ->
            Html.Styled.div [ Attr.css [ Tw.m_8, Tw.mt_0, Tw.pt_60 ] ]
                [ viewSpinnerWithText "Loading your tenants"
                ]

        SessionError err ->
            Html.Styled.div [ Attr.css [ Tw.m_8, Tw.mt_0, Tw.pt_60 ] ]
                [ Html.Styled.div [ Attr.css [ Tw.text_color Theme.danger ] ]
                    [ Html.Styled.text (Utils.Http.httpErrorToString err)
                    ]
                ]

        TenantsError err ->
            Html.Styled.div [ Attr.css [ Tw.m_8, Tw.mt_0, Tw.pt_60 ] ]
                [ Html.Styled.div [ Attr.css [ Tw.text_color Theme.danger ] ]
                    [ Html.Styled.text <| "Tenants load error: " ++ Utils.Http.customGraphqlErrorToString err
                    ]
                ]

        SessionOk model ->
            viewApp model


viewApp : ModelData -> Html Msg
viewApp ({ commons } as model) =
    let
        viewSubModule : (subModel -> Html SessionReadyMsg) -> Maybe subModel -> Html SessionReadyMsg
        viewSubModule view_ maybeModel =
            case maybeModel of
                Just subModel ->
                    view_ subModel

                Nothing ->
                    Html.Styled.div [ Attr.css [ Tw.m_8 ] ]
                        [ viewSpinnerWithText "Loading ..."
                        ]

        content : () -> Html SessionReadyMsg
        content _ =
            case model.page of
                Scheduler_v2 ->
                    viewSubModule
                        (Scheduler_v2.view commons >> Html.Styled.map GotSchedulerV2Msg)
                        model.subModels.scheduler_v2

                Pricing ->
                    viewSubModule
                        (Pricing.view commons model.store >> Html.Styled.map GotPricingMsg)
                        model.subModels.pricing

                Pricing_v2 _ ->
                    viewSubModule
                        (Pricing_v2.view commons model.store.locations >> Html.Styled.map GotPricingV2Msg)
                        model.subModels.pricing_v2

                Graph ->
                    viewSubModule
                        (Graph.view >> Html.Styled.map GotGraphMsg)
                        model.subModels.graph

                Actor _ ->
                    viewSubModule
                        (ActorDetail.view >> Html.Styled.map ActorDetailMsg)
                        model.subModels.actor

                Director _ ->
                    viewSubModule
                        (DirectorDetail.view >> Html.Styled.map DirectorDetailMsg)
                        model.subModels.directorDetail

                SeatsIO ->
                    viewSubModule
                        (SeatsIO.view >> Html.Styled.map GotSeatsIOMsg)
                        model.subModels.seatsIO

                UserPage ->
                    viewSubModule
                        (UserPage.view >> Html.Styled.map GotUserPageMsg)
                        model.subModels.userPage

                UserManagement ->
                    viewSubModule
                        (UserManagement.view commons >> Html.Styled.map GotUserManagementMsg)
                        model.subModels.userManagement

                Concessions concessionMenu ->
                    case concessionMenu of
                        Routes.Products ->
                            viewSubModule
                                (Concessions.view commons model.store >> Html.Styled.map GotConcessionsMsg)
                                model.subModels.concessions

                        Routes.Menu ->
                            viewSubModule
                                (Combos.view commons model.store >> Html.Styled.map GotCombosMsg)
                                model.subModels.combos

                        Routes.Collections ->
                            viewSubModule
                                (Collections.view commons model.store >> Html.Styled.map GotCollectionsMsg)
                                model.subModels.collections

                Accounting _ ->
                    viewSubModule
                        (Accounting.view model.store commons.tenantsInfo.tenant >> Html.Styled.map GotAccountPlanMsg)
                        model.subModels.accountPlan

                AccountSettings subPage ->
                    let
                        seatCharts : List Chart
                        seatCharts =
                            RD.withDefault [] model.store.seatCharts
                    in
                    viewSubModule
                        (AccountSettings.view commons model.store.locations seatCharts subPage >> Html.Styled.map GotAccountSettingsMsg)
                        model.subModels.accountSettings

                MetabaseReport subReport ->
                    viewSubModule (Backoffice.MetabaseReport.view subReport >> Html.Styled.map GotMetabaseMsg) model.subModels.metabaseReport

                Planning ->
                    viewSubModule
                        (Planning.view model.store >> Html.Styled.map GotPlanningMsg)
                        model.subModels.planning

                Loyalty ->
                    viewSubModule
                        (Loyalty.view model.store commons >> Html.Styled.map GotLoyaltyMsg)
                        model.subModels.loyalty

                CreditSales ->
                    viewSubModule
                        (CreditSales.view model.store commons >> Html.Styled.map GotCreditSalesMsg)
                        model.subModels.creditSales

                Library _ ->
                    viewSubModule
                        (Library.view commons >> Html.Styled.map GotLibraryMsg)
                        model.subModels.library
    in
    if not <| Toggles.isActive commons Toggles.MaintenanceMode then
        div [ Attr.css [] ]
            [ div
                [ Attr.css
                    [ Tw.grid
                    , Tw.absolute
                    , Tw.inset_0
                    , Css.property "grid-template-columns" "12rem 1fr"
                    ]
                ]
                [ div [ Attr.css [ Tw.w_48 ] ]
                    [ Menu.view commons
                        { page = model.page
                        , url = model.url
                        , logoutModel = model.logoutModel
                        }
                        model.menu
                        |> Html.Styled.map MenuMsg
                    ]
                , div
                    [ Attr.css
                        [ Tw.flex_grow
                        , Tw.relative
                        , Tw.h_screen
                        ]
                    ]
                    [ div
                        [ Attr.css
                            [ Tw.flex
                            , Tw.h_full
                            ]
                        ]
                        [ content () ]
                    , if List.isEmpty model.toastsQueue then
                        Html.Styled.text ""

                      else
                        model.toastsQueue
                            |> List.take maxShownToasts
                            |> List.map Toast.view
                            |> div
                                [ Attr.css
                                    [ Tw.absolute
                                    , Tw.bottom_4
                                    , Tw.left_1over2
                                    , Tw.neg_translate_x_2over4
                                    , Tw.z_50
                                    ]
                                ]
                    ]
                ]
            ]
            |> Html.Styled.map SessionReady

    else
        MaintenanceMode.view model.commons.static.maintenanceBackground



-- MAIN


main : Program Flags Model Msg
main =
    Browser.application
        { init = init
        , view = view
        , update = update
        , subscriptions = subscriptions
        , onUrlChange = UrlChanged >> SessionReady
        , onUrlRequest = LinkClicked >> SessionReady
        }
